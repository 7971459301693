import {LitElement, html, css, TemplateResult} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';

@customElement('references-button')
export class ReferencesButton extends LitElement {
  static override styles = css`
    .overlay {
      width: 101px;
    }
    
    .display-none {
      display: none;
    }
    
    .url-list {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      background-color: #FFFFFF;
      padding: 8px 16px 8px 16px;
      gap: 10px;
      border-radius: 8px;
      width: 605px;
    }
    
    sl-button.ref-button::part(base) {
      color: #626F78;
      border: 1px solid #EFEFEF;
      border-radius: 8px;
      background-color: transparent;
      height: 36px;
      align-items: center;
    }

    sl-button.ref-button::part(base):hover {
      background-color: #E9E9E9;
      color: #626F78;
    }

    sl-button.ref-button::part(label) {
      font-family: "Neue Haas Grotesk Display Pro", sans-serif;
      padding: 0.5rem;
    }
  `;

  @property({type: Boolean})  toggled: boolean = true;
  @property({type: Array}) urls: string[] = [];
  @query('#urlList') private urlList?: HTMLElement;

  override render() {
    return html`
        <div class="overlay">
            <sl-button class="ref-button" variant="default" size="small" @click='${() => this.toggle()}'>
                <sl-icon slot="suffix" name="${this.toggled ? 'chevron-down' : 'chevron-up'}"></sl-icon>
                References
            </sl-button>
            <div class="${this.toggled ? 'display-none' : ''}">
              <div id="urlList" class="url-list">
                  ${this.renderUrls()}
              </div>
            </div>
        </div>
      `;
  }

  renderUrls(): TemplateResult[] {
    const htmlElements: TemplateResult[] = [];
    for (const url of this.urls) {
      const fileName = url.split(" page: ")[0]
      const pageNumber = url.split(" page: ")[1]
      const newUrl = `https://teamitgassets.blob.core.windows.net/pdfs/${fileName}?sp=r&st=2024-01-17T10:32:37Z&se=2024-01-18T18:32:37Z&spr=https&sv=2022-11-02&sr=c&sig=TE0IHh4OLawNb8p8%2FYTxk5IxLPbIeCTxA2vs2pgX8Q4%3D#page=${pageNumber}`
      htmlElements.push(html`<a href="${newUrl}" target=”_blank”>${url}</a>`);
    }

    return htmlElements;
  }

  toggle() {
    this.toggled = !this.toggled;
    if (this.urlList) {
      this.urlList.hidden = this.toggled;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'references-button': ReferencesButton;
  }
}
